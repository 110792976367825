@font-face {
  font-family: Kanit;
  src: url("./fonts/Kanit-Thin.ttf");
}

@font-face {
  font-family: KanitMedium;
  src: url("./fonts/Kanit-Medium.ttf");
}

@font-face {
  font-family: KanitRegular;
  src: url("./fonts/Kanit-Regular.ttf");
}

@font-face {
  font-family: KanitSemiBold;
  src: url("./fonts/Kanit-SemiBold.ttf");
}

* {
  scroll-behavior: smooth;
  font-family: 'Kanit', sans-serif;
  /* background-image: url("./image/BG-02.jpg"); */
}

html,
body {
  /* height: 100%; */
  /* padding: 0; */
  margin: 0;
  overflow-x: hidden;
  /* background-image: url(./image/BG-02.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background: url(./image/BG-02.jpg) no-repeat fixed center;
}

body {
  padding-top: 60px;
  /* padding-bottom: 40px; */

  overflow-x: hidden;
  /* width: 100vw; */
  
}

.font-KanitRegular {
  font-family: 'KanitRegular', sans-serif;
}

.font-KanitSemiBold {
  font-family: 'KanitSemiBold', sans-serif;
}

.container {
  width: 100vw;
  margin: 0 auto;
}

.header {
  width: 100vw;
  position: fixed;
  top: 0;
  font-weight: 700;
  color: #707070;
  z-index: 999
}

nav a {
  color: #fff;
  text-decoration: none;
  padding: 4px 25px;
  display: inline-block;
}

@media only screen and (max-width: 1322px) {
  nav a {
    padding: 4px 18px;
  }
}

@media only screen and (max-width:1187px) {
  nav a {
    padding: 4px 10px;
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-top: 5px;
  margin-bottom: -5px;
  margin-left: 16px;
}

.aboutUs-text {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  width: 75vw;
  font-size: 1em;
  font-family: 'KanitMedium', sans-serif;
}

.navbar {}

.navbar a:hover {
  color: #0277C1 !important;
  font-family: 'KanitMedium', sans-serif;
}

section {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  /* min-height: calc(100vh - 65px);*/
  height: 100%;
  overflow: hidden;
}

.row-content {
  width: 75vw;
}

.btn {
  background-color: initial;
  border: 1px solid #0277c1;
  color: #0277c1;
  height: 42px;
  margin-top: 18px;
  width: 131px;
}

.h1-evBike {
  color: #0277c1;
  font-size: 2.5em;
}

.text-evBike {
  color: #707070;
  font-family: KanitMedium, sans-serif;
  font-size: 1em;
  width: 75vw;
}

.btn>span,
.h1-evBike {
  font-family: KanitMedium, sans-serif;
}

.btn>span {
  font-weight: 700;
}

.container-rental {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-card-meta-title {
  color: #707070;
  font-family: KanitMedium, sans-serif;
  font-size: 1.1em;
  font-weight: 400;
}

.text-card-meta {
  color: #707070;
  font-family: Kanit, sans-serif;
  font-weight: 700;
}

.more-card {
  color: #0277c1;
  cursor: pointer;
  text-align: right;
  position: absolute;
  bottom: 20px;
  width: 85%;
}

.more {
  color: #0277c1;
  cursor: pointer;
  text-align: right;
}

.more,.more-card,
.textContact {
  font-family: KanitMedium, sans-serif;
}

.textContact {
  margin-left: 4%;
}

.image_rental {
  border-radius: 20px;
  width: 75vw
}

/*  */
.ant-menu-item-selected>.ant-menu-title-content>a {
  font-family: 'KanitMedium', sans-serif;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after {
  border-bottom-color: #0277C1;
  border-bottom-width: 4px;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item {
  margin-top: 4px;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item:hover::after {
  border-bottom-color: #0277C1;
  border-bottom-width: 4px;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item:after {
  border-bottom: solid 4px #0277C1;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item:after {
  transform-origin: 0% 50%;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item:hover:after {
  transform: scaleX(1);
  transform-origin: 0% 100%;
}

.image-promotion {
  border-radius: 8px 8px 0 0;
  display: block;
  width: 100%;
}

.text-news {
  color: #707070;
  font-family: KanitRegular, sans-serif;
  font-size: .875em;
  padding: 2px 0;
}

.h1-news {
  font-family: KanitMedium, sans-serif;
  color: #0277c1;
  font-size: 2.0em;
  margin: 0px;
  line-height: 1.3;
}

.h2-news {
  color: #0277c1;
  font-size: 1.2em;
}

/* .select-lang {
  top: 9px;
  order: 8;
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  padding-inline: 16px;
  cursor: pointer;
} */

.btn-lang {
  padding: 5px;
  border: 1px solid;
  border-radius: 100%;
  margin: 4px;
}

.ant-btn-primary {
  color: #fff !important;
  background-color: #0277c1;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  animation-name: changeColor;
  animation-duration: .5s;
}

.ant-btn-primary:not(:disabled):active {
  color: #fff;
  background-color: #0277c1;
}

.ant-btn-lang {
  margin: 4px;
  border: 1px solid #0277c1;
  color: #0277c1;
  font-family: KanitRegular, sans-serif;
}


.ant-btn-lang>span {
  font-family: KanitRegular, sans-serif;
}

li.select-lang:after {
  border-bottom-width: 0px !important;
}

li.select-lang:hover::after {
  border-bottom-width: 0px !important;
}

.select-lang {
  /* padding-top: 4px; */
}

@keyframes changeColor {
  from {
    background-color: #fff;
  }

  to {
    background-color: #0277c1;
  }
}

.menuMobile {
  font-family: KanitRegular, sans-serif;
}

.ant-menu-horizontal .ant-menu-item {
  padding-inline: 4px;
}

.ant-btn-primary:not(:disabled):hover {
  color: #0277c1 !important;
  background-color: #fff;
  border-color: #0277c1;
}


.ant-btn-default:not(:disabled):hover {
  color: #fff !important;
  background-color: #0277c1;
  border-color: #0277c1;
}

.ant-card .ant-card-body {
  height: 170px;
}

video {
  border-radius: 8px;
  min-height: 270px;
}

.video-home{
  z-index: 3;
    position: relative;
}